import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { rootLoader } from '@/api/loaders';
import App from '@/App';

import { ROUTES } from './paths';

const Amount = lazy(() => import('@pages/Amount/Amount'));
const Payment = lazy(() => import('@pages/Payment/Payment.index'));
const Products = lazy(() => import('@pages/Products/Products.index'));
const ProcessPayment = lazy(() => import('@pages/Process/ProcessPayment'));
const Success = lazy(() => import('@pages/Response/Success'));
const Response = lazy(() => import('@pages/Response/Response'));
const Static = lazy(() => import('@pages/StaticPayment/Static'));

export const merchantRoutes: RouteObject = {  
  path: ROUTES.MERCHANT,
  element: <App />,
  id: 'root',
  loader: rootLoader,
  children: [
    {
      path: ROUTES.AMOUNT,
      element: <Amount />,
      index: true,
    },
    {
      path: ROUTES.PAYMENT.AMOUNT,
      element: <Payment />,
    },
    {
      path: ROUTES.PAYMENT.STATIC,
      element: <Payment />,
    },
    {
      path: ROUTES.PRODUCTS.LIST,
      element: <Products />,
    },
    {
      path: ROUTES.PRODUCTS.DETAIL,
      element: <Payment />,
    },
    {
      path: ROUTES.PAYMENT.PROCESS,
      element: <ProcessPayment />,
    },
    {
      path: ROUTES.PAYMENT.SUCCESS,
      element: <Success />,
    },
    {
      path: ROUTES.PAYMENT.RESPONSE,
      element: <Response />,
    },
    {
      path: ROUTES.STATIC,
      element: <Static />,
    }
  ]
}; 