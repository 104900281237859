import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { merchantRoutes } from './merchant.routes';
import { ROUTES } from './paths';

const Root = lazy(() => import('@pages/Root'));
const Home = lazy(() => import('@pages/Home'));
const Mint = lazy(() => import('@pages/Mint/Mint'));
const Error = lazy(() => import('@pages/Response/Error'));

export const router = createBrowserRouter([
  {
    path: ROUTES.HOME,
    element: <Root />,
    errorElement: <Error/>,
    children: [
      {
        element: <Home />,
        index: true,
      },
      {
        path: ROUTES.MINT,
        element: <Mint />,
      },
      merchantRoutes
    ],
  },
]); 