import './index.css';
import '@radix-ui/themes/styles.css';
import './i18n.js';

import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import Loader from './pages/Process/Loader';
import { router } from './routes';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Suspense fallback={<Loader/>}>
    <RouterProvider router={router}/>
  </Suspense>
);
