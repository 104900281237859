export const ROUTES = {
  HOME: '/',
  MINT: '/mint',
  MERCHANT: '/:slug',
  AMOUNT: '',
  PAYMENT: {
    AMOUNT: 'amount/:amount',
    STATIC: 'payment/:payment_id/static?',
    PROCESS: 'payment/:payment_id/process',
    SUCCESS: 'payment/:payment_id/success',
    RESPONSE: 'payment/:payment_id/response'
  },
  PRODUCTS: {
    LIST: 'products',
    DETAIL: 'products/:product_id'
  },
  STATIC: 'static'
} as const; 